<template>
  <div class="iot-person public-page-style">
    
    <div class="iot-person-info public-shadow">
     
      <el-form ref="userRef" :model="accountForm" label-width="80px">
        <h3 style="margin:0 0 40px 0">个人信息</h3>
        <el-form-item label="账号" prop="username">
          <el-input v-model="accountForm.username"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="fullName">
          <el-input v-model="accountForm.fullName"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="accountForm.mobile"></el-input>
        </el-form-item>
        <el-form-item label="创建时间" prop="createTime">
          <el-input v-model="accountForm.createTime" disabled></el-input>
        </el-form-item>
        <el-form-item label="激活状态" prop="userState">
          <el-input
            :value="accountForm.userState == 0 ? '激活' : '未激活'"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roleName">
          <el-input v-model="accountForm.roleName" disabled></el-input>
        </el-form-item>
        <el-button
          class="iot-baseinfo-submit"
          type="primary"
          plain
          size="mini"
          @click="submitUserForm"
          >修改</el-button
        >
        
      </el-form>
    </div>
    
  </div>
  
</template>

<script>
import { baseUrl } from "../util/global";
import { OSS_URL, PLATFORM_IMG } from "../util/constant";
let that;
export default {
  name: "BaseInfo",
  props: {
    msg: String,
  },
  data() {
    return {
      constant: {
        OSS_URL,
        PLATFORM_IMG,
      },
      accountForm: {
        userId: "",
        username: "",
        mobile: "",
        createTime: "",
        userState: "",
        roleId: "",
        roleName: "",
      },
      user: {
        userId: "",
        mobile: "",
      },

      platformImgUpload: baseUrl + "img",
      imgUrl: "",
    };
  },
  methods: {
    handleAvatarSuccess(res) {
      if (200 == res.code) {
        that.imgUrl = res.data;
        that.$set(that.platform, "platformLogo", that.imgUrl);
      }
    },
    beforeAvatarUpload(file) {
      that.$refs["imgUpload"].validate((valid) => {
        if (valid) {
          const isImg =
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png" ||
            file.type === "image/gif";
          if (!isImg) {
            this.$message.error("图片格式仅限jpeg，jpg，png，gif!");
          }
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error("图片大小不得超过2MB!");
          }
          return isImg && isLt2M;
        } else {
          this.$message.error("请查看表单信息是否有遗漏！");
        }
      });
    },
    getBaseInfo() {
      that.$api.account
        .getAccount(that.$store.state.userId)
        .then((response) => {
          if (response.code === 200) {
            that.accountForm = response.data;
          }
        });
    },

    submitUserForm() {
      that.$refs["userRef"].validate((valid) => {
        if (valid) {
          that.user = {
            userId: that.accountForm.userId,
            username: that.accountForm.username,
            fullName:that.accountForm.fullName,
            mobile: that.accountForm.mobile,
            roleId: that.accountForm.roleId,
          };
          that.$api.account.editAccount(that.user).then((response) => {
            if (response.code == 200) {
              that.$refs.userRef.resetFields();
              that.$message({
                type: "success",
                message: response.msg,
              });
              that.getBaseInfo();
            }
          });
        }
      });
    },
  
  },

  beforeMount() {
    that = this;
  },
  mounted() {
    that.getBaseInfo();
  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 900;
}
.iot-person {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.iot-person-info {
  width: 38%;
  height: 65%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.iot-person-info >>> .el-form {
  width: 80%;
}
.avatar-uploader >>> .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader >>> .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 140px;
  height: 140px;
  line-height: 140px;
  text-align: center;
}
.avatar {
  width: 140px;
  height: 140px;
  display: block;
}
.iot-baseinfo-submit {
  margin-left: calc(50% - 40px);
}
</style>
